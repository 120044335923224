import React from "React"
import {Link } from "gatsby"
import { Helmet } from "react-helmet"

const Layout = ({ children }) => {
	return(
		<>
			<Helmet htmlAttributes={{ lang: 'en' }}>
				<meta name="description" content="Webdevelopment &amp; IT-Consulting from Hamburg, Germany" />
				<meta name="theme-color" content="#000000" />
			</Helmet>
	    <main>
	    { children }
	    </main>
	    <footer>
	      <div className="url"><Link to="/" activeClassName="current">Home</Link></div>
	      <div className="url"><Link to="/imprint" activeClassName="current" partiallyActive={true}>Imprint</Link></div>
	      <div className="url"><Link to="/privacy" activeClassName="current" partiallyActive={true}>Privacy</Link></div>
	    </footer>
  	</>
	)
}

export default Layout